import React from "react";
import SEO from "../../components/seo";
import HorizontalLine from "../../components/horizontal-line";
import "../../assets/sass/articles.scss";

const PromoteWebsitePl = (props) => {
    return (
        <div location={props.location}>
            <SEO title="Jak stworzyć stronę" language="pl"/>
            <article className="article">
                <div className="container">
                    <div className="article__header">
                        <HorizontalLine classname="white"/>
                        <h1 className="article__header--title">Jak wygląda nowoczesna strona internetowa?</h1>
                        <HorizontalLine classname="white"/>
                        <h2 className="article__header--subtitle">Myślisz; nowoczesna strona internetowa a mówisz; taka,
                            która tworzy udany związek
                            z estetyką. No właśnie. Czym się charakteryzuje taka strona? Czym właściwie jest
                            nowoczesność na stronach internetowych? Czy chodzi tu o responsywność, czy o
                            narzędzia do szybkiego kontaktu z klientem i bycie labradorem interakcji
                            społecznych? Poniżej znajdziesz odpowiedź jak programują postępowi web
                            developerzy.
                        </h2>
                    </div>
                    <h3 className="article__section--title">Certyfikat SSL.</h3>
                    <p className="article__text">
                        Nowoczesna strona to przede wszystkim bezpieczna strona. Tu pierwsze skrzypce
                        gra tak zwany Certyfikat SSL, który gwarantuje bezpieczeństwo i pewność w
                        przypadku jakiejkolwiek wymiany danych. Ponadto witryny posiadające ten certyfikat
                        zyskują dodatkowe punkty w wynikach wyszukiwania. Niezabezpieczone strony
                        budzą również pewien niepokój wśród internautów.
                    </p>
                    <h3 className="article__section--title">Małe też piękne, czyli styl flat.</h3>
                    <p className="article__text">
                        To nie jest tak, że strona z łaskawością przyjmie każdą nową przestrzeń, czy każdy
                        nowy element. W przypadku wielu atrakcyjnych witryn zdecydowanie króluje
                        minimalizm, czy inaczej flat design. To element, który jest utożsamiany z
                        nowoczesnym podejściem do stron internetowych. To również ograniczenie
                        agresywnych gradientów, nic nie znaczących animacji czy też jaskrawych kolorów
                        na rzecz tych bardziej stonowanych, prostych kształtów i przyjaznych fontów.
                        Projektowanie płaskie skupia więc swoją uwagę na funkcjonalności.
                    </p>
                    <h3 className="article__section--title">Szybki i sprawny kontakt z klientem - narzędzia.</h3>
                    <p className="article__text">
                        Nowoczesność to też nowoczesne narzędzia, które zachęcą klienta do szybkiego
                        kontaktu. Bardzo pożądane są niestandardowe metody kontaktu, formularz powoli
                        odchodzi do lamusa. Nowoczesne narzędzia to: live chat, integracja strony z
                        Messengerem czy narzędzie do zamawiania kontaktu, często spotykane na stronach
                        hoteli.
                    </p>
                    <h3 className="article__section--title">Hero Image.</h3>
                    <p className="article__text">
                        Bezsprzecznie to jeden z gorętszych trendów jeśli chodzi o projektowanie
                        nowoczesnych stron. Hero Image to nic innego jak główny bohater strony. To główne
                        dość spore zdjęcie, albo slajder prezentujący motyw przewodni strony. Wszystko to
                        jest opatrzone dość krótkim ciekawym sloganem przedstawiającym wartość
                        nadrzędną marki. Doskonałym przykładem jest witryna Netflix’a oraz Apple’a.
                    </p>
                    <h3 className="article__section--title">Blog firmowy i wartościowe treści.</h3>
                    <p className="article__text">
                        Treści na stronie internetowej to absolutna podstawa. Pełnią cały wachlarz funkcji;
                        od informacyjnej, przez promocyjnej aż po ułatwienie sprzedaży usług bądź
                        produktów. Ponadto dobre teksty umacniają wysoką pozycję strony w wyszukiwarce
                        internetowej. Warto pamiętać o odpowiednim rozmieszczeniu treści na stronie i o
                        prowadzeniu bloga firmowego, który nie tylko ułatwi nam budowanie korzystnego
                        wizerunku autorytetu w swojej dziedzinie, ale również wzmocni pozycję w sieci.
                    </p>
                    <h3 className="article__section--title">Call to Action - CTA.</h3>
                    <p className="article__text">
                        Cel każdej strony internetowej to aktywne pozyskiwanie klientów i nakłonienie ich do
                        wykonanej jakiejś konkretnej czynności. Może to być subskrypcja newslettera, może
                        być zakup czy też nawiązanie kontaktu. Za to wszystko odpowiada przycisk CTA,
                        który ma za zadanie skutecznie skierować klienta na właściwy cel.
                        Przycisk powinien wyróżniać się na tle strony i przyciągać internautów za pomocą
                        chwytliwego hasła.
                    </p>
                    <h3 className="article__section--title">Ilustracje.</h3>
                    <p className="article__text">
                        Ilustracje to kolejny element, który definiuje stronę jako tą nowoczesną. Odpowiednio
                        dobrane przenoszą w całkiem inny świat, tworzą ciekawy klimat i zatrzymują na
                        dłużej. Dzięki ilustracjom Twoja strona będzie odbierana w pozytywnym świetle a
                        grafiki zapewnią jej wyróżnienie się na tle innych.
                    </p>
                    <h3 className="article__section--title">Animacje.</h3>
                    <p className="article__text">
                        Subtelne animacje coraz częściej wchodzą nieśmiało w świat stron internetowych.
                        Nadają im nieco dynamiki i smaku. Ożywiają statyczne grafiki i wprawiają tekst w
                        ruch.
                    </p>
                    <h3 className="article__section--title">Wideo.</h3>
                    <p className="article__text">
                        Zaraz obok ilustracji, jako kolejny trend plasuje się wideo. Najczęściej film jest
                        umiejscowiony w hero image, gdzie należy przedstawić jakąś usługę lub produkt.
                    </p>
                    <blockquote>
                        <p>
                            Efektywne wykorzystanie powyższych elementów z pewnością da spektakularny
                            efekt w postaci nowoczesnej strony internetowej. Taka współczesna i stylowa strona
                            z pewnością nie przejdzie szybko do historii a pozwoli na realizację wielu
                            biznesowych celów. Realizację projektu strony oddaj w ręce profesjonalistów i daj
                            się pozytywnie zaskoczyć!
                        </p>
                    </blockquote>
                </div>
            </article>
        </div>
    );
};
export default PromoteWebsitePl;